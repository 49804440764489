import React from 'react';
import Header from '../khelocricket/header';
import Banner from './banner';
import Aboutus from './aboutus';
import HowToPlay from './Howtoplay';
import AboutUssec from './aboutkhelo';
import Testimonials from './testimonial';
import FAQ from './Faq';

const Homepage = () => {
  return (
    <div>
      <Header />
      <Banner/>
      <Aboutus/>
      <HowToPlay/>
      <AboutUssec/>
      <FAQ/>
      <Testimonials/>
    </div>

  );
};

export default Homepage;
