import React from "react";

const Banner = () => {
  return (
    <div className="w-full mt-10 p-10 h-auto bg-gradient-to-r from-teal-900 to-gray-600">
      <div className="text-center text-white">
        <h1 className="text-5xl font-extrabold mb-4">
          Get Ready for an Ultimate Fantasy Sports Adventure
        </h1>
        <p className="text-lg mb-6 max-w-2xl mx-auto">
          Immerse yourself in the world of Fantasy Cricket with PlayXI. 
          Use your cricket knowledge and strategic gaming skills to create your 
          dream team, compete with other enthusiasts, and stand a chance to win exciting prizes!
        </p>
        <div className="flex justify-center gap-8">
          <button
         onClick={() => window.open("https://wa.link/indraadsgg", "_blank")}
          className="bg-teal-600 hover:bg-teal-700 text-white font-semibold py-3 px-8 rounded-lg text-lg transition duration-300">
            Join Now
          </button>
          <button
          onClick={() => window.open("https://wa.link/indraadsgg", "_blank")}
          className="bg-transparent border-2 border-white text-white hover:bg-white hover:text-teal-600 font-semibold py-3 px-8 rounded-lg text-lg transition duration-300">
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
