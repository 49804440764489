import React from "react";

const Header = () => {
  return (
    <div className="grid w-full px-4 sm:px-10 lg:px-32 mt-10 lg:mt-20 h-screen lg:grid-cols-2 grid-cols-1 gap-8">
      {/* Left Content */}
      <div className="flex flex-col justify-center items-center lg:items-start space-y-6 text-center lg:text-left">
        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold">
          Get Ready for an Ultimate Fantasy Sports Adventure
        </h1>
        <div className="flex flex-col sm:flex-row justify-start w-full items-center sm:items-start gap-3">
          <input
            className="p-2 border border-black w-full sm:w-auto"
            placeholder="Enter your mobile number"
            type="text"
          />
          <button
            onClick={() => window.open("https://wa.link/indraadsgg", "_blank")}
            className="bg-blue-500 text-white px-4 py-2 rounded-md w-full sm:w-auto"
          >
            Submit
          </button>
        </div>
      </div>

      {/* Right Content */}
      <div className="w-full flex justify-center items-center">
        <img
          className="w-full max-w-sm md:max-w-md lg:max-w-full h-auto object-contain"
          src="/crciket ai.jpg"
          alt="Cricket Illustration"
        />
      </div>
    </div>
  );
};

export default Header;
