import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`fixed z-30 top-0 left-0 right-0 py-3 font-RobotoRegular px-5 md:px-20 text-black transition-all duration-300 ease-in-out ${
        scrolled
          ? "bg-gradient-to-r from-teal-500 to-gray-600 shadow-xl"
          : "bg-transparent"
      }`}
    >
      <div className="flex items-center justify-between">
        <div className="w-28 h-12 flex items-center">
          <h1 className="text-xl text-teal-700 font-bold">PlayXI</h1>
        </div>

        <div className="md:hidden cursor-pointer" onClick={toggleMenu}>
          <FaBars className="text-gray-900" size={24} />
        </div>

        <div className="hidden md:flex text-lg text-black md:space-x-8 items-center">
          <span
          onClick={() => window.open("https://wa.link/indraadsgg", "_blank")}
          className="hover:text-green-600 font-semibold transition-colors duration-200 cursor-pointer">
            Home
          </span>
          <span
          onClick={() => window.open("https://wa.link/indraadsgg", "_blank")}
          className="hover:text-green-600 font-semibold transition-colors duration-200 cursor-pointer">
            About
          </span>
          <span 
          onClick={() => window.open("https://wa.link/indraadsgg", "_blank")}
          className="hover:text-green-600 font-semibold transition-colors duration-200 cursor-pointer">
            How it Works
          </span>
          <div 
          onClick={() => window.open("https://wa.link/indraadsgg", "_blank")}
          className="hover:text-green-600 font-semibold transition-colors duration-200 cursor-pointer">
            Contact us
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div
          className={`flex flex-col items-center bg-gray-800 text-white rounded-xl pb-10 md:hidden mt-4 space-y-4 transition-transform duration-300 ${
            isOpen ? "translate-y-0" : "-translate-y-6 opacity-0"
          }`}
        >
          <span
              
            onClick={toggleMenu}
            className="hover:text-teal-300 hover:bg-teal-700 rounded-md px-10 py-2 transition-colors duration-200 cursor-pointer"
          >
            <span onClick={() => window.open("https://wa.link/indraadsgg", "_blank")}>Home</span>
          </span>
          <span
            onClick={toggleMenu}
            className="hover:text-teal-300 hover:bg-teal-700 rounded-md px-10 py-2 transition-colors duration-200 cursor-pointer"
          >
            About
          </span>
          <span
            onClick={toggleMenu}
            className="hover:text-teal-300 hover:bg-teal-700 rounded-md px-10 py-2 transition-colors duration-200 cursor-pointer"
          >
            How it Works
          </span>

          <div className="bg-gradient-to-r from-teal-600 to-blue-500 rounded-md px-6 py-2 text-white font-semibold cursor-pointer transition duration-300 transform hover:scale-105 hover:shadow-lg">
           Contact us
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
