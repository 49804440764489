import React from "react";

const Aboutus = () => {
  return (
    <div className="grid w-full px-4 sm:px-10 lg:px-20 mt-10 h-auto lg:h-screen lg:grid-cols-2 grid-cols-1 gap-8">
      {/* Left Section */}
      <div className="flex flex-col justify-center items-center lg:items-start space-y-6 text-center lg:text-left">
        <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold leading-snug">
          Fantasy Sports
          <br />
          <span className="text-xl sm:text-2xl">
            Start Your Fantasy Sport Journey!
          </span>
        </h1>
        <p className="text-sm sm:text-base lg:text-lg leading-relaxed">
          Embark on your fantasy sports journey with PlayXI! Build winning
          teams, participate in thrilling contests, and showcase your sports
          knowledge across fantasy cricket, football, and more. Whether you are
          a seasoned player or just getting started, our platform offers an
          engaging experience with exciting rewards and competitions. Join now,
          compete with other enthusiasts, and turn your passion for sports into
          daily wins!
        </p>
      </div>

      {/* Right Section */}
      <div className="flex justify-center items-center mt-6 lg:mt-0">
        <img
          className="w-full max-w-sm md:max-w-md lg:max-w-lg h-auto object-contain"
          src="/6449.jpg"
          alt="Fantasy Sports"
        />
      </div>
    </div>
  );
};

export default Aboutus;
