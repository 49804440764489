import React from "react";

const HowToPlay = () => {
  const steps = [
    {
      step: "Step 1",
      title: "Select a Match",
      description:
        "Choose an upcoming match from a variety of sports, including cricket, football, and more.",
    },
    {
      step: "Step 2",
      title: "Create Your Team",
      description:
        "Build your dream team by selecting players within the allocated budget. Pick the perfect combination of performers.",
    },
    {
      step: "Step 3",
      title: "Join Contests",
      description:
        "Enter contests with your team and compete against other fantasy sports enthusiasts to win exciting rewards.",
    },
    {
      step: "Step 4",
      title: "Track Your Team",
      description:
        "Watch the match and follow your team’s performance in real time to see how your players score points.",
    },
  ];

  return (
    <section className="py-16 bg-gradient-to-r from-teal-900 to-gray-600 text-white">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-12">
          How To Play Fantasy Sports?
        </h2>
        <div className="grid grid-cols-1 px-10 cursor-pointer  md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <div
              onClick={() =>
                window.open("https://wa.link/indraadsgg", "_blank")
              }
              key={index}
              className="bg-white transition-transform duration-300 transform hover:scale-105  text-black p-6 rounded-lg shadow-md hover:shadow-xl"
            >
              <div className="mb-4">
                <h3 className="text-xl font-bold text-teal-600">{step.step}</h3>
              </div>
              <h4 className="text-lg font-semibold mb-2">{step.title}</h4>
              <p className="text-gray-700">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowToPlay;
