import React from "react";

const AboutUssec = () => {
  return (
    <section className="py-16 mt-20 bg-gray-100">
      <div className="container mx-auto flex flex-col lg:flex-row items-center">
        {/* Image Section */}
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <img
            src="/crciket ai.jpg" // Replace with your actual image URL
            alt="About Khelocricket11"
            className="rounded-lg shadow-lg w-full h-[50vh] object-contain"
          />
        </div>

        {/* Text Section */}
        <div className="lg:w-1/2 lg:pl-12 text-center lg:text-left">
          <h2 className="text-4xl font-bold text-gray-800 mb-6">
            About PlayXI
          </h2>
          <p className="text-gray-700 leading-relaxed mb-4">
            Welcome to **PlayXI**, India's go-to destination for daily
            fantasy sports. Created with passion and precision by **Digital
            Astitva LLP**, our platform brings the excitement of fantasy sports
            right to your fingertips. Whether it’s cricket, football, hockey, or kabaddi, PlayXI offers an immersive experience for every sports enthusiast.
          </p>
          <p className="text-gray-700 leading-relaxed mb-6">
            Dive into the action with our user-friendly mobile app! Pick your
            favorite match, craft your dream team, and compete in thrilling
            contests for a chance to win amazing rewards. Fantasy sports have
            never been this exciting and accessible.
          </p>
          <a
            href="#"
            className="bg-gradient-to-r from-teal-600 to-blue-500 text-white px-8 py-3 rounded-md font-semibold shadow-md hover:scale-105 transition-transform"
          >
            Learn More
          </a>
        </div>
      </div>
    </section>
  );
};

export default AboutUssec;
